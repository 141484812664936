import React from 'react'
import { useAuthRevisor } from '../../hooks/useAuth'

interface Props {
    title: string
    children: any
}

const PageLayout = ({ children, title }: Props) => {
    useAuthRevisor('/revisor/')

    return (
        <div>
            <main>{children}</main>
        </div>
    )
}

export default PageLayout
