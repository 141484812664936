import LoginRevisor from '../components/LoginRevisor'
import React from 'react'
import Pagewrapper from '../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <LoginRevisor />
        </Pagewrapper>
    )
}
